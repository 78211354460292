function TVModeEnabler() {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const tvON = 'tvON';
    const tvOFF = 'tvOFF';

    const isTVModeEnabled = localStorage.getItem(tvON);

    if (urlParams.has(tvON) || isTVModeEnabled) {
      localStorage.setItem(tvON, 1);
      document.documentElement.classList.add(tvON);
    }

    if (urlParams.has(tvOFF)) {
      localStorage.removeItem(tvON);
      document.documentElement.classList.remove(tvON);
    }
  } catch (error) {
    throw new Error('Local storage is not available. TV mode settings cannot be saved.');
  }
}

export default TVModeEnabler;
