import FontFaceObserver from 'fontfaceobserver';

function loadFontAndActivate() {
  function activateFont() {
    document.documentElement.classList.add('wf-active');
  }
  try {
    if (sessionStorage && sessionStorage.fonts) {
      activateFont();
    }
  } catch (e) {
    throw new Error(`Failed to activate font: ${e.message}`);
  }

  const font = new FontFaceObserver('Open Sans');
  const maxRetries = 3;
  let retryCount = 0;

  Promise.all([font.load()]).then(
    () => {
      setTimeout(() => {
        activateFont();
        try {
          sessionStorage.setItem('fonts', true);
        } catch (e) {
          throw new Error(`Failed to set sessionStorage: ${e.message}`);
        }
      }, 0);
    },
    (err) => {
      if (retryCount >= maxRetries) {
        throw new Error(`Font loading timeout: ${err}`);
      }
      retryCount += 1;
      loadFontAndActivate();
    },
  );
}

export default loadFontAndActivate;
